import {AbstractControl} from '@angular/forms';

export const Patterns = {
	url: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
	number: /^\d{0,8}[.]?\d{1,2}$/,
	alphanumeric: /^[\w\s! éèà'-_]+$/,
	password_8: /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/,
	password_12: /^\S*(?=\S{12,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/,
	names: /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/,
	phone: /^(\(0\))?[0-9]+$/,
	email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/,

	isValid: (ctrl?: AbstractControl | null, err?: any): boolean => {
		if (ctrl) {
			return ctrl.hasError(err.type) && (ctrl.dirty || ctrl.touched);
		} else {
			return true;
		}
	},

	isEmpty: (str: string): boolean => !str || 0 === str.length,

	isBlank: (str: string): boolean => !str || /^\s*$/.test(str),

	now: (): Date => new Date(Date.now()),
};
